<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Page from '@zyro-inc/site-modules/components/Page.vue';

import {
	SYSTEM_LOCALE,
	PAGE_ID_PRIVATE,
} from '@zyro-inc/site-modules/constants';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getPageData } from '@zyro-inc/site-modules/utils/getPageData';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

const {
	website,
	setIsSiteEngine,
} = useSiteGlobal();
const route = useRoute();

const currentLocale = computed(() => {
	if (!website.value) {
		return null;
	}

	if (website.value.meta.isPrivateModeActive) {
		return SYSTEM_LOCALE;
	}

	const { locale } = getPathParams({
		path: route.path,
		defaultLocale: website.value.meta.defaultLocale ?? SYSTEM_LOCALE,
		languageKeys: Object.keys(website.value.languages),
	});

	return locale;
});

const pageData = computed(() => {
	if (!website.value) {
		return null;
	}

	const pageId = website.value.meta.isPrivateModeActive
		? PAGE_ID_PRIVATE
		: getPageIdFromPath({
			path: route.path,
			siteData: website.value,
		});

	return getPageData({
		siteData: website.value,
		locale: currentLocale.value,
		pageId,
		isAstro: false,
		isPreviewMode: false,
		isCustomCodeDisabled: false,
	});
});

setIsSiteEngine(true);
</script>

<template>
	<Page
		v-if="pageData"
		:page-data="pageData"
	/>
</template>
