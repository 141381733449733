<template>
	<a
		class="block-header-logo"
		:href="props.href"
	>
		<div
			v-if="props.logoSvg"
			class="block-header-logo__image"
			v-html="props.logoSvg"
		/>
		<img
			v-else-if="props.logoSrc"
			v-qa="'builder-siteheader-img-logo'"
			class="block-header-logo__image"
			:src="props.logoSrc"
			:alt="logoAltTag"
		>
	</a>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	logoSrc: string;
	logoSvg: string;
	href: string;
	height: number;
	heightMobile: number;
	maxWidth: string;
	maxWidthMobile: string;
	siteName: string;
}

const props = defineProps<Props>();

const logoAltTag = computed(() => (props.siteName ? `${props.siteName} logo` : ''));
const height = computed(() => `${props.height}px`);
const mHeight = computed(() => `${props.heightMobile}px`);
const width = computed(() => props.maxWidth);
const mWidth = computed(() => props.maxWidthMobile || width.value);
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block-header-logo {
	display: flex;
	width: v-bind(width);
	height: v-bind(height);
	cursor: pointer;

	&__image {
		width: 100%;
		height: 100%;
		vertical-align: middle;
		object-fit: contain;
	}
}

@include site-engine-mobile {
	.block-header-logo {
		width: v-bind(mWidth);
		height: v-bind(mHeight);
	}
}
</style>
